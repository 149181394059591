import React, { useState,useEffect } from "react";
import { DataGrid , GridColumn  } from "@mui/x-data-grid";
import useStyles from "./styles";
var count=0
const getwidth =(props)=>{
  var keys ={}
  for (let i = 1; i < props.dataArray.length; i++) {
    keys = Object.keys(props.dataArray[i]);
  
  
  }
  return keys
}


const ReportTbl=(props)=>{
  const [media,setmedia] = useState(false)

//  useEffect(()=>{
//   const mediaQuery = window.matchMedia('(min-width: 1800px)');

//   setmedia(mediaQuery.matches); // Output: true or false
//  })
 

  const classes = useStyles();
  var thisCompany = props.headerColor?.split(",")[0];
  const size  = props.dataArray.length; 
  const containerHeight = props.dataArray.length > 12 ?   368 + props.dataArray.length * 50 :  368 + props.dataArray.length * 28; 
  const fullh =  5400

var keyslength = getwidth(props)
 
  var innerWidthOfTable = (keyslength.length-1) * 255
  


   return(
    // <div className={`table animatingBottom ${thisCompany} withoutscroller`} style={{height:`${size > 99 ?fullh :  containerHeight}px`,width:`${innerWidthOfTable}px`,background:'#fff'}}>
        <div className={`table animatingBottom ${thisCompany} withoutscroller`} style={{height:'100vh',width:`${innerWidthOfTable}px`,background:'#fff'}}>


    <DataGrid
    rows={props.dataArray}
    columns={props.TblColumns}
    pageSize={size > 99 ? 100 : size}
    getRowId={(row) => row.Id}
    className={`${classes[thisCompany]}` }
    classes={{
      header: 'sticky-header',
    }}
    onSelectionModelChange={(ids) => {
      const selectedIDs = new Set(ids);
      const selectedRows = props.dataArray.filter((row) =>
        selectedIDs.has(row.Id),
      );

    }}
      {...props.dataArray}
    />
    </div>
   )
}
export default ReportTbl;

