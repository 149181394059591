import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./styles";
import { CSVLink } from "react-csv";
import axios from "axios";
import {
  GET_COLLECTION_REPORT, COMPANY_CODE, GET_ALL_COUTER, GET_ALL_USER, GET_ALL_CATEGORIES, GET_SALE_SUMMARY_REPORT,
  GET_DETAILED_SALE_REPORT, GET_SALE_RETURN_REPORT, GET_SALE_REPORT, GET_COUNTER_DETAIL_REPORT,
  GET_BILL_COMMISSION_REPORT, GET_CLIENT_WISE_COMMISSION_REPORT, GET_SUMMARY_COMMISSION_REPORT,
  GET_ITEM_SUMMARY_REPORT, SELECTED_COMPANY_TYPE, GET_CATEGORY_SALE_REPORT, GET_TABLE_DETAILS_REPORT, GET_VIEW_KOT_DETAILS_REPORT, GET_VIEW_FINANCIAL_DETAILS_REPORT, GET_VIEW_CLIENT_TRACKING_DETAILS_REPORT, GET_VIEW_EXPENCE_DETAILS_REPORT
} from "../../../constant";
import ReportTbl from "./ReportTbl"
import Alertmessage from "../../../Utiles/Alert/Alertmessage";
export default function CollectionReportMain(props) {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const selectRef = useRef(null);
  const [collectionReportType, setCollectionReportType] = useState("SELECT");
  const [collectionreporttbl, setcollectionreporttbl] = useState([]);
  const [allCounts, SetAllCounts] = useState([]);
  const [strWh, setStrWh] = useState([]);
  const [allUser, SetAllUser] = useState([]);
  const [allCategories, SetAllCategories] = useState([]);
  const [salecollectiontbl, setsalecollectiontbl] = useState(false)
  const [isLoading, setisLoading] = useState(false);
  const [collectionsummaryclm, SetCollectionsummaryclm] = useState([]);
  const [Saleclm, setSaleclm] = useState([])
  const [BillWiseCommsissionClm, setBillWiseCommsissionClm] = useState([])
  const [CounterDetailsClm, setCounterDetailsClm] = useState([])
  const [salesummaryclm, Setsalesummaryclm] = useState([]);
  const [detailSaleclm, SetdetailSaleclm] = useState([]);
  const [categorySaleyclm, SetCategorySaleyclm] = useState([]);
  const [tableDetailclm, SetTableDetailclm] = useState([]);
  const [KOTDetailclm, SetKOTDetailclm] = useState([]);
  const [ItemSummaryClm, setItemSummaryClm] = useState([])
  const [FinancialDetailclm, SetFinancialDetailclm] = useState([]);
  const [ClientTrackingclm, setClientTrackingclm] = useState([])
  const [Expenceclm, setExpenceclm] = useState([])
  const [companyColor, setcompanyColor] = useState("");
  const [totalQTY, setTotalQTY] = useState(0);
  const [TotalBasicAmount, setTotalBasicAmount] = useState(0);
  const [isalert, setisalert] = useState(false)
  const [alertbg, setalertbg] = useState("")
  const [alertmsg, setalertmsg] = useState("")
  const [blankmsg, setblankmsg] = useState(collectionReportType)
  const bgerror = "#d9534f";
  const bgsuccess = "#5cb85c";
  // const[TotalDiscountAmount, setTotalDiscountAmount]= usestate(0);
  const [activeButton, setActiveButton] = useState("");
  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [fromdate, setfromdate] = useState(today)
  const [todate, settodate] = useState(nextday)
  const [collectionData, setCollectionData] = useState({
    "FormDate": "",
    "ToDate": "",
    "CompanyNumber": COMPANY_CODE,
    "strWh": "",
    "SystemName": "",
    "Euser": "",
    "Category": ""
  });

  // let collectionsummaryclm =[];
  // setTimeout(() => {
  //   setisalert(false);
  // }, 5000);
  useEffect(() => {


    setcompanyColor(localStorage.getItem('cmpnyType'))
    // document.querySelector('.TOGGLEicon').click();
    var a = window.location.href;

    //API FOT GET ALL ESYSTEM
    axios.post(GET_ALL_COUTER, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
      if (response.data.IsSuccess === true) {
        SetAllCounts(response.data.Data);

      }
      else {
        // var emptList=[];
        SetAllCounts([]);
      }
    })
    //API FOR GET ALL EUSER
    axios.post(GET_ALL_USER, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
      if (response.data.IsSuccess === true) {
        SetAllUser(response.data.Data);
      }
      else {
        // var emptList=[];
        SetAllUser([]);
      }
    })
    //API FOR GET ALL CATEGORIES
    axios.post(GET_ALL_CATEGORIES, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
      console.log(response)
      if (response.data.IsSuccess === true) {
        SetAllCategories(response.data.Data);
      }
      else {
        // var emptList=[];
        SetAllCategories([]);
      }
    })

  }, []);
  const handelCollectionChange = (e) => {
    setCollectionReportType(e.target.name);

    setcollectionreporttbl([]);
  }

  const handlereportbtnClick = (e) => {
    setActiveButton(e.target.name)
    setCollectionReportType(e.target.name);
    setblankmsg(e.target.innerHTML)
    setcollectionreporttbl([]);

  }
  const handlesaleSummaryRbtn = () => {
    setsalecollectiontbl(true)
    // setmaintbl(false)

  }

  // get table sum 
  const getSum = (rows, field) => {
    return rows.reduce((sum, row) => sum + row[field], 0);
  }


  const handleCollectionChange = (e) => {
    // setActiveButton(e.target.name);
    let strWh = "";
    let strWhArr = [];
    if (e.target.name === "SaleOrder" || e.target.name === "MainOrder" || e.target.name === "VRoute" || e.target.name === "COUNTER") {
      if (e.target.checked) {
        //setStrWh(strWh => [...strWh,e.target.value] );
        strWhArr.push(e.target.value);
      }
      else {
        if (strWhArr.indexOf(e.target.value) > -1) {
          strWhArr.splice(strWh.indexOf(e.target.value), 1);
        }
      }
      strWh = strWhArr.join(",");
      setCollectionData({ ...collectionData, "strWh": strWh });
      return;
    }
    setCollectionData({ ...collectionData, [e.target.name]: e.target.value });


  }
  const getReport = () => {


    collectionData.FormDate = fromdate
    collectionData.ToDate = todate;
    var from = new Date(collectionData.FormDate);
    var To = new Date(collectionData.ToDate);
    setisLoading(true)
    if (fromdate === "" || todate === "") {
      setisalert(true)
      setalertmsg("from date and to date should not blank.");
      setalertbg(bgerror)
      setisLoading(false)

    }

    else if (from > To) {
      setisalert(true)
      setalertmsg("from date can't be greater than To Date .");
      setalertbg(bgerror)
      setisLoading(false)

    }
    else if (collectionReportType === "SELECT") {
      setisalert(true)
      setalertmsg("Please Select The Report Type.");
      setalertbg(bgerror)
      setisLoading(false)

    }
    else {
      Setsalesummaryclm([]);
      if (collectionReportType === "collection") {

        SetCollectionsummaryclm([])
        axios.post(GET_COLLECTION_REPORT, collectionData
        ).then((response) => {
          console.log(response)
          setisLoading(false);
          if (response.data.IsSuccess === true) {
            debugger;
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                SetCollectionsummaryclm(collectionsummaryclm => [...collectionsummaryclm, obj]);
              }

            });
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "SaleSummary") {

        axios.post(GET_SALE_SUMMARY_REPORT, collectionData
        ).then((response) => {
          console.log("item summary")
          console.log(response)
          if (response.data.IsSuccess === true) {
            Setsalesummaryclm([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    headerName: "Created AT",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };

                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                Setsalesummaryclm(salesummaryclm => [...salesummaryclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            setblankmsg("NO BILL SUMMARY FOUND !")
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
            setTimeout(() => {
              setblankmsg("")
            }, 5000);
          }
        })
      }
      else if (collectionReportType === "DetailedSale") {
        axios.post(GET_DETAILED_SALE_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            SetdetailSaleclm([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    headerName: "Created AT",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };

                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                SetdetailSaleclm(detailSaleclm => [...detailSaleclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "SaleReturn") {
        axios.post(GET_SALE_RETURN_REPORT, collectionData
        ).then((response) => {
          debugger;
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "SaleReport") { //SaleReport
        axios.post(GET_SALE_REPORT, collectionData
        ).then((response) => {
          debugger;
          if (response.data.IsSuccess === true) {
            setSaleclm([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    headerName: "Created AT",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };

                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                setSaleclm(Saleclm => [...Saleclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "CounterDetail") {   //setCounterDetailsClm  CounterDetailsClm
        axios.post(GET_COUNTER_DETAIL_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setSaleclm([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    headerName: "Created AT",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };
                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                setCounterDetailsClm(CounterDetailsClm => [...CounterDetailsClm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "BillWiseCommsission") { //BillWiseCommsissionClm  setBillWiseCommsissionClm
        axios.post(GET_BILL_COMMISSION_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setSaleclm([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    headerName: "Created AT",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };
                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                setBillWiseCommsissionClm(BillWiseCommsissionClm => [...BillWiseCommsissionClm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "ClientWiseCommsission") {
        axios.post(GET_CLIENT_WISE_COMMISSION_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "SummaryCommission") {

        axios.post(GET_SUMMARY_COMMISSION_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "ItemSummary") {

        if (collectionData.Category == '') {
          setisalert(true)
          setalertmsg("Please Select category");
          setalertbg(bgerror)
          setisLoading(false)
          setisLoading(false)
          return;
        }
        selectRef.current.click();
        console.log("item summ collection")
        console.log(collectionData)
        var from = new Date(collectionData.FormDate);
        var To = new Date(collectionData.ToDate);
        axios.post(GET_ITEM_SUMMARY_REPORT, collectionData
        ).then((response) => {
          console.log(response)
          if (response.data.IsSuccess === true) {
            setItemSummaryClm([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    headerName: "Created AT",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };

                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                setItemSummaryClm(ItemSummaryClm => [...ItemSummaryClm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "categorySale") {
        SetCategorySaleyclm([]);
        axios.post(GET_CATEGORY_SALE_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            var totalQty = 0, totalBaciAmt = 0;
            debugger;
            response.data.Data.map((data, i) => {
              totalQty = data.Quantity + totalQty;
              totalBaciAmt = data.BasicAmount + totalBaciAmt;

            })
            setTotalQTY(totalQty);
            setTotalBasicAmount(totalBaciAmt);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                SetCategorySaleyclm(categorySaleyclm => [...categorySaleyclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "tableDetails") {
        axios.post(GET_TABLE_DETAILS_REPORT, collectionData
        ).then((response) => {
          SetTableDetailclm([]);
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    headerName: "Date",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };

                }
                else {

                  obj = { "field": data, "headerName": data, width: 250 };
                  SetTableDetailclm(tableDetailclm => [...tableDetailclm, obj]);
                }
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "KOTReport") {

        //kot details 
        SetKOTDetailclm([]);
        axios.post(GET_VIEW_KOT_DETAILS_REPORT, collectionData
        ).then((response) => {
          console.log()
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                SetKOTDetailclm(KOTDetailclm => [...KOTDetailclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "Financial") {
        SetFinancialDetailclm([]);
        //kot details 
        axios.post(GET_VIEW_FINANCIAL_DETAILS_REPORT, collectionData
        ).then((response) => {
          console.log(response)
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data.Table);
            let obj = {};
            const keys = Object.keys(response.data.Data.Table[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                SetFinancialDetailclm(FinancialDetailclm => [...FinancialDetailclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "clienttracking") {
        setClientTrackingclm([]);
        //kot details 
        axios.post(GET_VIEW_CLIENT_TRACKING_DETAILS_REPORT, collectionData
        ).then((response) => {
          console.log(response)
          debugger;
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              debugger;
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                setClientTrackingclm(ClientTrackingclm => [...ClientTrackingclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (collectionReportType === "Expence") {

        //kot details 
        setExpenceclm([]);
        axios.post(GET_VIEW_EXPENCE_DETAILS_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                setExpenceclm(Expenceclm => [...Expenceclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }




      else if (from.getTime() > To.getTime()) {
        setisalert(true)
        setalertmsg("from date and to date should not blank.");
        setalertbg(bgerror)
        setisLoading(false)
        setisLoading(false)
      }

      else {
        axios.post(GET_ITEM_SUMMARY_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            console.log(response)
            setcollectionreporttbl(response.data.Data);
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
    }

  }

  // const detailedSaleclm = [
  //   {
  //     field: "CreatedDate",
  //     headerName: "Created AT",
  //     width: 200,
  //     disableClickEventBubbling: true,
  //     renderCell: (params) => {
  //             if(params?.row?.CreatedDate?.split("T")[0]==="1990-01-01")
  //             {
  //               return (
  //                 <p className=' mt-2 f-14'></p>
  //               );
  //             }
  //             else
  //             {
  //               return (
  //                 <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
  //               );
  //             }

  //     }
  //   },
  //   { field: 'BillNumber', headerName: 'Bill Number', width: 200 },
  //   { field: 'UserName', headerName: 'UserName', width: 200 },
  //   { field: 'BasicAmount', headerName: 'Basic Amount', width: 200 },
  //   { field: 'DiscountAmount', headerName: 'Discount Amount', width: 200 },
  //   { field: 'Item', headerName: 'Item', width: 200 },
  //   { field: 'Type', headerName: 'Type', width: 200 },
  //   { field: 'BillAmount', headerName: 'Bill Amount', width: 200 },
  //   { field: 'NetAmount', headerName: 'Net Amount', width: 200 },
  //   { field: 'Deposit', headerName: 'Deposit', width: 200 },
  //   { field: 'GSTPercent', headerName: 'GST%', width: 200 },
  //   { field: 'GSTAmt', headerName: 'GST Amt', width: 200 },
  //   { field: 'SoldBy', headerName: 'Sold By', width: 200 },

  // ]
  // const Saleclm = [
  //   { field: 'Category', headerName: 'Category', width: 100 },
  //   { field: 'Qty', headerName: 'Qty', width: 160 },
  //   { field: 'BasicAmount', headerName: 'Basic Amount', width: 160 },
  //   { field: 'DiscountAmount', headerName: 'Discount Amount', width: 100 },
  //   { field: 'NetAmount', headerName: 'Net Amount', width: 150 },
  //   { field: 'GrossAmt', headerName: 'Gross Amount', width: 130 },
  // ]
  // const CounterDetailsClm = [
  //   {
  //     field: "CreatedDate",
  //     headerName: "Created AT",
  //     width: 200,
  //     disableClickEventBubbling: true,
  //     renderCell: (params) => {

  //       return (
  //         <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
  //       );
  //     }
  //   },
  //   { field: 'TblNo', headerName: 'Table Number', width: 200 },
  //   { field: 'count', headerName: 'count', width: 200 },
  //   { field: 'SaleAmt', headerName: 'Sale Amount', width: 200 },
  // ]
  // const BillWiseCommsissionClm = [
  //   {
  //     field: "CreatedDate",
  //     headerName: "Created AT",
  //     width: 200,
  //     disableClickEventBubbling: true,
  //     renderCell: (params) => {

  //       return (
  //         <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
  //       );
  //     }
  //   },
  //   { field: 'CompanyTotal', headerName: 'Company Total', width: 200 },
  //   { field: 'Client', headerName: 'Client', width: 200 },
  //   { field: 'Bill', headerName: 'Bill', width: 200 },
  //   { field: 'Qty', headerName: 'Qty', width: 200 },
  //   { field: 'Sale', headerName: 'Sale', width: 200 },
  //   { field: 'Percentage', headerName: 'Percentage', width: 200 },
  //   { field: 'Commission', headerName: 'Commission', width: 200 },
  // ]
  const SummaryWiseCommsissionClm = [
    { field: 'CompanyTotal', headerName: 'Company', width: 200 },
    { field: 'Qty', headerName: 'Qty', width: 200 },
    { field: 'Sale', headerName: 'Sale', width: 200 },
    { field: 'Commission', headerName: 'Commission', width: 200 },
  ]
  // const ItemSummaryClm = [
  //   { field: 'Item', headerName: 'Item', width: 200 },
  //   { field: 'Type', headerName: 'Type', width: 200 },
  //   { field: 'Quantity', headerName: 'Qty', width: 200 },
  //   { field: 'Rate', headerName: 'Rate', width: 200 },
  //   { field: 'Basic Amount', headerName: 'Basic Amount', width: 200 },
  //   { field: 'Discount Amount', headerName: 'Discount Amount', width: 200 },
  //   { field: 'Net Amount', headerName: 'Net Amount', width: 200 },
  //   { field: 'GST %', headerName: 'GST %', width: 200 },
  //   { field: 'GST Amount', headerName: 'GST Amt', width: 200 },
  //   { field: 'Gross Amount', headerName: 'Gross Amt', width: 200 },
  //   { field: 'Deposit', headerName: 'Deposit', width: 200 },
  // ]
  const goBackPage = () => {
    //navigate(-1);
    history.goBack();
  }
  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
      {
        isalert ? <Alertmessage bgcolor={alertbg} alertmsg={alertmsg} onClose={() => setisalert(false)} /> : null
      }

      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-5 col-12">
            <table className="collection_Table" >
              <tr>
                <td>
                  <button name="collection" className={`${activeButton === "collection" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Collection</button>
                  {/* <input className={`${activeButton === "collection" ? 'activeCollectionbtn' : ''} form-check-input `} type="radio" name="collection" id="collection" value="collection" defaultChecked={true} onChange={e => { handelCollectionChange(e) }} /> */}
                  {/* <label className="form-check-label text-dark" for="inlineRadio1">Collection</label> */}
                </td>
                {SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "" :
                  <td>
                    <button name="Vatreport" className={`${activeButton === "Vatreport" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>VAT Report</button>
                    {/* <input className="form-check-input" type="radio" name="collection" id="VAT" value="VAT" onChange={e => { handelCollectionChange(e) }} /> */}
                    {/* <label className="form-check-label text-dark" for="inlineRadio1">VAT Report</label> */}
                  </td>
                }
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="Expence" className={`${activeButton === "Expence" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Expence</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="Expence" value="Expence" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Expence</label> */}
                    </td>
                    :
                    <td>
                      <button name="CounterDetail" className={`${activeButton === "CounterDetail" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Counter Detail</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="CounterDetail" value="CounterDetail" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Counter Detail</label> */}
                    </td>
                }

              </tr>
              <tr>
                <td>
                  <button name="SaleSummary" className={`${activeButton === "SaleSummary" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Bill Summary" : "Sale Summary"}</button>
                  {/* <input className="form-check-input" type="radio" name="collection" id="SaleSummary" value="SaleSummary" onChange={e => { handelCollectionChange(e) }} /> */}
                  {/* <label className="form-check-label text-dark" for="inlineRadio1" >{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Bill Summary" : "Sale Summary"}</label> */}
                </td>
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "" :
                    <td>
                      <button name="categorySale" className={`${activeButton === "categorySale" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Category" : "Category Sale"}</button>
                    </td>
                }

                <td>
                  <button name="DetailedSale" className={`${activeButton === "DetailedSale" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Bill Detail" : "Detailed Sale"}</button>
                  {/* <input className="form-check-input" type="radio" name="collection" id="categorySale" value="categorySale" onChange={e => { handelCollectionChange(e) }} /> */}
                  {/* <label className="form-check-label text-dark" for="inlineRadio1">{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Category" : "Category Sale"}</label> */}
                </td>
              </tr>
              <tr>
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="categorySale" className={`${activeButton === "categorySale" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Category" : "Category Sale"}</button>


                      {/* <input className="form-check-input" type="radio" name="collection" id="DetailedSale" value="DetailedSale" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Bill Detail" : "Detailed Sale"}</label> */}
                    </td>
                    : ""}
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="KOTReport" className={`${activeButton === "KOTReport" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>KOT report</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="KOTReport" value="KOTReport"  onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">KOT report</label> */}
                    </td>
                    :
                    <td>
                    </td>
                }

                <td>

                </td>
              </tr>
              <tr>
                {SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                  <td>
                    <button name="tableDetails" className={`${activeButton === "tableDetails" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Table Details</button>
                    {/* <input className="form-check-input" type="radio" name="collection" id="tableDetails" value="tableDetails" onChange={e => { handelCollectionChange(e) }} /> */}
                    {/* <label className="form-check-label text-dark" for="inlineRadio1">Table Details</label> */}
                  </td> :
                  <td>
                    <button name="SaleReturn" className={`${activeButton === "SaleReturn" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Sale Return</button>
                    {/* <input className="form-check-input" type="radio" name="collection" id="SaleReturn" value="SaleReturn" onChange={e => { handelCollectionChange(e) }} /> */}
                    {/* <label className="form-check-label text-dark" for="inlineRadio1">Sale Return</label> */}
                  </td>}
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    ""

                    :
                    <td>
                      <button name="Day_Collection" className={`${activeButton === "Day_Collection" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Day Collection</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="Day_Collection" value="Day_Collection" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Day Collection</label> */}
                    </td>
                }
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="clienttracking" className={`${activeButton === "clienttracking" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Client Tracking</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="SummaryCommission" value="clienttracking" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Client Tracking</label> */}
                    </td>
                    :
                    <td>
                      <button name="SummaryCommission" className={`${activeButton === "SummaryCommission" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Summary Commission</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="SummaryCommission" value="SummaryCommission" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Summary Commission</label> */}
                    </td>
                }

              </tr>
              <tr>

                <td>
                  <button name="ItemSummary" id="ItemSummary" className={`${activeButton === "ItemSummary" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Item Details" : "Item Summary"}</button>
                  {/* <input className="form-check-input" type="radio" name="collection" id="ItemSummary" value="ItemSummary" onChange={e => { handelCollectionChange(e) }} /> */}
                  {/* <label className="form-check-label text-dark" for="inlineRadio1">{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT"?"Item Details":"Item Summary"}</label> */}
                </td>


                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "" :
                    <td>
                      <button name="P&L" className={`${activeButton === "P&L" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>P & L</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="P&L" value="P&L" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">P & L</label> */}
                    </td>
                }
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="Financial" className={`${activeButton === "Financial" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Financial</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="BillWiseCommsission" value="Financial" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Financial</label> */}
                    </td>
                    :
                    <td>
                      <button name="BillWiseCommsission" className={`${activeButton === "BillWiseCommsission" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Bill Wise Commsission</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="BillWiseCommsission" value="BillWiseCommsission" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Bill Wise Commsission</label> */}
                    </td>
                }

              </tr>
              <tr>
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    ""
                    :
                    <td>
                      <button name="SaleReport" className={`${activeButton === "SaleReport" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Sale Report</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="SaleReport" value="SaleReport"  onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Sale report</label> */}
                    </td>
                }

                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "" :
                    <td>
                      <button name="colPerioudExpenseslection" className={`${activeButton === "colPerioudExpenseslection" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Period Expenses</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="PerioudExpenses" value="colPerioudExpenseslection" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Period Expenses</label> */}
                    </td>

                }
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? <td></td> :
                    <td>
                      <button name="ClientWiseCommsission" className={`${activeButton === "ClientWiseCommsission" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Client Wise Commsission</button>
                      {/* <input className="form-check-input" type="radio" name="collection" id="ClientWiseCommsission" value="ClientWiseCommsission" onChange={e => { handelCollectionChange(e) }} /> */}
                      {/* <label className="form-check-label text-dark" for="inlineRadio1">Client Wise Commsission</label> */}
                    </td>
                }

              </tr>
            </table>

          </div>
          <div className="col-md-7 col-sm-7 col-12 ">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-12">
                <div className="col-auto d-flex">
                  <label for="date" className="col-form-label">FROM</label>
                  <input type="date" className="form-control w-75 input_date" id="FDate" style={{ marginBottom: "5px" }} name="FormDate" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />

                </div>
                <div className="col-auto d-flex">
                  <label for="date" className="col-form-label">To</label>
                  <input type="date" className="form-control w-75" id="ToDate" style={{ marginBottom: "5px" }} name="ToDate" value={todate} onChange={(e) => settodate(e.target.value)} />
                </div>
                {
                  SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ?
                    "" :
                    <>
                      <div className="col-auto d-flex">
                        <label for="date" className="col-form-label">Counter</label>
                        <select className="w-75" name="SystemName" style={{ marginBottom: "5px" }} onChange={e => { handleCollectionChange(e) }}>
                          <option value="">--Select Counter--</option>
                          {
                            allCounts.map((data, i) => {
                              return (<option value={data.ESYSTEM}>{data.ESYSTEM}</option>);
                            })
                          }

                        </select>
                      </div>
                      <div className="col-auto d-flex">
                        <label for="date" className="col-form-label">User</label>
                        <select name="Euser" style={{ marginBottom: "5px", marginTop: "2px" }} onChange={e => { handleCollectionChange(e) }}>
                          <option value="">--Select USER--</option>
                          {allUser.map((data, i) => {
                            return (<option value={data.EUSER}>{data.EUSER}</option>);
                          })}
                        </select>
                      </div>
                    </>
                }

                <div className="col-auto d-flex">
                  <label for="date" className="col-form-label">Category</label>
                  <select ref={selectRef} className="w-75" id="categoryDD" name="Category" style={{ marginBottom: "5px" }} disabled={collectionReportType === "ItemSummary" ? false : true} onChange={e => { handleCollectionChange(e) }}>
                    <option value="">--Select Category--</option>
                    {
                      allCategories.map((data, i) => {
                        return (<option value={data.Type}>{data.Category}</option>);
                      })
                    }

                  </select>
                </div>
              </div >

              <div className="col-md-3 col-sm-6 col-12">
                {
                  SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ?
                    "" :
                    <>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="disabledFieldsetCheck" value="SO" name="SaleOrder" onChange={e => { handleCollectionChange(e) }} />
                        <label className="form-check-label" for="disabledFieldsetCheck">
                          Sale Order
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="disabledFieldsetCheck" value="COUNTER" name="COUNTER" onChange={e => { handleCollectionChange(e) }} />
                        <label className="form-check-label" for="disabledFieldsetCheck">
                          Counter
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="disabledFieldsetCheck" value="MAIN" name="MainOrder" onChange={e => { handleCollectionChange(e) }} />
                        <label className="form-check-label" for="disabledFieldsetCheck">
                          Main Order
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="disabledFieldsetCheck" value="VRoute" name="VRoute" onChange={e => { handleCollectionChange(e) }} />
                        <label className="form-check-label" for="disabledFieldsetCheck">
                          V Route
                        </label>
                      </div>

                    </>
                }


              </div>
              <div className="col-md-2 col-sm-3 col-6">
                {
                  SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ?

                    "" :
                    <>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="disabledFieldsetCheck" />
                        <label className="form-check-label" for="disabledFieldsetCheck">
                          Report By VAT
                        </label>
                        <select style={{ width: "100%" }} disabled={collectionReportType === "DetailedSale" || collectionReportType === "ItemSummary" ? false : true}>
                          <option value="">--Select an option--</option>
                          <option value="ALLL">ALL</option>
                          <option value="VAT">VAT</option>
                          <option value="MTV">MTV</option>
                          <option value="EXEMPT">EXEMPT</option>
                        </select>
                      </div>

                    </>
                }

              </div>
              <div className={` col-md-2 col-sm-3 col-6 collection_brnGrp ${SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}>

                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} onClick={getReport}>Display</button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} >View Client</button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} >Print </button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} disabled={collectionreporttbl?.length == 0 ? true : false} ><CSVLink data={collectionreporttbl} className="text-white"> EXCEL</CSVLink></button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} onClick={goBackPage}>exit</button>
              </div>
            </div>
          </div>
        </div >
      </div >
      <hr className={`${classes[companyColor?.split(',')[0] + 'TEXT']}`} />
      <p className={`${classes[companyColor?.split(',')[0] + 'TEXT']}`} style={{ textAlign: 'center', fontWeight: '700' }} >{blankmsg.toUpperCase()} &nbsp; REPORT</p>
      <div className={`${SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ? " row restronTableDiv" : " row posTableDiv"}`}>
        <div className="col-md-12 col-sm-12 col-12">
          {
            collectionReportType === "collection" && collectionsummaryclm.length > 0 ?
              <ReportTbl dataArray={collectionreporttbl} TblColumns={collectionsummaryclm} headerColor={companyColor} /> : ""
          }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
          {
            collectionReportType === "SaleSummary" && salesummaryclm.length > 0 ?
              <ReportTbl dataArray={collectionreporttbl} TblColumns={salesummaryclm} headerColor={companyColor} /> : ""

          }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "DetailedSale" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={detailSaleclm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "SaleReturn" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={detailSaleclm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "SaleReport" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={Saleclm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "CounterDetail" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={CounterDetailsClm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "BillWiseCommsission" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={BillWiseCommsissionClm} headerColor={companyColor} /> : ""

        }</div>
          <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "ClientWiseCommsission" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={BillWiseCommsissionClm} headerColor={companyColor} /> : ""

        }</div>
           <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "SummaryCommission" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={SummaryWiseCommsissionClm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "ItemSummary" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={ItemSummaryClm} headerColor={companyColor} /> : ""

        }
     </div>
     <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "categorySale" && categorySaleyclm.length > 0 ?
            <> <ReportTbl dataArray={collectionreporttbl} TblColumns={categorySaleyclm} headerColor={companyColor} /><div className="row">
              <div className="col-md-2 col-sm-2 col-2"><h6>Total Quantity:<span>{totalQTY}</span></h6>
              </div>
              <div className="col-md-2 col-sm-2 col-2"><h6>Total Basic Amount:<span>{TotalBasicAmount}</span></h6>
              </div>
            </div> </> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "tableDetails" && tableDetailclm.length > 0 ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={tableDetailclm} headerColor={companyColor} /> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "KOTReport" && KOTDetailclm.length > 0 ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={KOTDetailclm} headerColor={companyColor} /> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "Financial" && FinancialDetailclm.length > 0 ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={FinancialDetailclm} headerColor={companyColor} /> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "clienttracking" && ClientTrackingclm.length > 0 ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={ClientTrackingclm} headerColor={companyColor} /> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "Expence" && Expenceclm.length > 0 ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={Expenceclm} headerColor={companyColor} /> : ""
        }
        </div>
      </div>

    </>
  );
}

