import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,TextField,
  Checkbox
} from "@material-ui/core";
import {useHistory } from "react-router-dom"
import { useTheme } from "@material-ui/styles";
import { CSVLink } from "react-csv";
// styles
import useStyles from "./styles";
import { DataGrid} from "@mui/x-data-grid";
import { useEffect } from "react";
import {GET_All_ACCOUNT,LOGIN_DETAILS,GET_LEDGER_REPORT,COMPANY_CODE} from '../../../constant';
import axios from "axios";

export default function LedgerReport(props) {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const [isSelectAc,setIsSelectAc]  = useState(false);
  const [nameOfAcc,setNameOfAcc] = useState("");
  const [ldgReport,setLdgReport] = useState([]);
  const [tabledata,settabledata] = useState([]);
  const [ldgAccList,setLdgAccList] = useState([]);
  const [ldgAcList,setLdgAcList] = useState([]);
  const [isLoading,setisLoading] = useState(false)
  const [selectedLdgAccIndex,setSelectedLdgAccIndex] = useState(0);
  const [companyColor, setcompanyColor] = useState("")
  const LedgerTblRow =[
    {
        field: "CreatedDate",
        headerName: "Created AT",
        width: 95,
        disableClickEventBubbling: true,
        renderCell: (params) => {
      
          return (
            <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
          );
        }
      },
    { field: 'Voucher', headerName: 'Voucher', width: 150  ,cellStyle: {color: 'red', 'background-color': 'green'}},
    { field: 'Nart', headerName: 'Naration', width: 150 },
    { field: 'Dabit', headerName: 'Debit', width: 150 },
    { field: 'Credit', headerName: 'Credit', width: 150 },
    { field: 'Currency', headerName: 'Currency', width: 150 },
    { field: 'Blnc', headerName: 'Balance', width: 150 },
    { field: 'CurrencyAmt', headerName: 'Currency AMT', width: 150 },
    { field: 'atVal', headerName: '@', width: 150 }, 
    { field: 'ActualCurrency', headerName: 'Actual Currency', width: 150 },
  ]
  const [ledgerAc,setLedgerAc] = useState({
    "FYear":LOGIN_DETAILS.FINANCIAL_YEAR,
    "CompanyNumber":COMPANY_CODE
  });
  const [ledger,setLedger] = useState({
    "FormDate":new Date(),
    "ToDate":new Date(),
    "chkLocalCurrency":0,
    "Mcode":"",
    "CompanyNumber":COMPANY_CODE
  });
  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
   //  document.querySelector('.TOGGLEicon').click();
  var a = window.location.href;

        axios.post(GET_All_ACCOUNT,ledgerAc
    ).then((response)=>{
    
      if(response.data.IsSuccess === true){
        setLdgAcList(response.data.Data);
      }
      else{
        // var emptList=[];
        setLdgAcList([]);
      }
    })
  },[]);
  const acTypeClicked=(type)=>
{
  if(type==="Selected")
  {
    setIsSelectAc(true);
  }
  else{
    setIsSelectAc(false);
  }
  setSelectedLdgAccIndex(0);
  setLedger({...ledger,"Mcode":""});
}
const getLedgerReportInNxtBtn=()=>
{
  //localStorage.setItem('ldgAccList_index', selectedLdgAccIndex);
  setisLoading(true)
  setSelectedLdgAccIndex(selectedLdgAccIndex+1);
  axios.post(GET_LEDGER_REPORT,{
    "FormDate":ledger.FormDate,
    "ToDate":ledger.ToDate,
    "chkLocalCurrency":ledger.chkLocalCurrency,
    "Mcode":ldgAccList[selectedLdgAccIndex+1],
    "CompanyNumber":COMPANY_CODE
  }
    ).then((response)=>{
      if(response.data.IsSuccess === true){
        setLdgReport(response.data.Data);
        setisLoading(false)
      }
      else{
        // var emptList=[];
        setLdgReport([]);
        setisLoading(false)
      }
    })
}
const onhandleAcChanges=(e)=>
{
  debugger;
  if(e.target.name=="fromAc"||e.target.name=="toAc")
  {
    let fromIndex=0,toIndex=0;
    ldgAcList.some((element,i) => {
      if (element.Mcode === e.target.value) {
        e.target.name=="fromAc"?fromIndex=i:toIndex=i;
      }
  
    });
    if(e.target.name=="toAc")
    {
     for(var i=fromIndex;i<=toIndex;i++)
     {
       debugger;
        setLdgAccList(ldgAccList => [...ldgAccList,ldgAcList[i].Mcode] );
     }
    }
   
  }
  else{
    setLdgAccList(ldgAccList => [...ldgAccList,e.target.value] );
  }
  setLedger({...ledger,"Mcode":e.target.value});
  setNameOfAcc(e.target.text);
}
const getLedgerReport=()=>
{
  setisLoading(true)
  if(ledger.FormDate===""||ledger.ToDate===""||ledger.Mcode==="")
  {
    alert("Account and from date and to date should not blank.");
    setisLoading(false)
  }
  else{
    axios.post(GET_LEDGER_REPORT,
      {
        "FormDate":ledger.FormDate,
        "ToDate":ledger.ToDate,
        "chkLocalCurrency":ledger.chkLocalCurrency,
        "Mcode":ldgAccList[0],
        "CompanyNumber":COMPANY_CODE
      }
      ).then((response)=>{
        debugger
        if(response.data.IsSuccess === true){
          setLdgReport(response.data.Data);
          setSelectedLdgAccIndex(0);
          setisLoading(false)
        }
        else{
          // var emptList=[];
          setLdgReport([]);
          setisLoading(false)
        }
      })
  }
}
const goBackPage=()=>
{
   //navigate(-1);
  history.goBack();
}
const handleLedgerChange=(e)=>
{
  if(e.target.name==="chkLocalCurrency" )
  {
    setLedger({...ledger,[e.target.name]:e.target.checked});
  }
  else
  {
    if(e.target.name==="Mcode")
    {
      if(e.target.checked)
      {
        setLdgAccList(ldgAccList => [...ldgAccList,e.target.value] );
      }
      else{
        if(ldgAccList.indexOf(e.target.value)>-1)
        {
          ldgAccList.splice(ldgAccList.indexOf(e.target.value), 1);
        }
      }
    }
      setLedger({...ledger,[e.target.name]:e.target.value});
  }
}

  return (
  <>
     {
    isLoading?  <div className='Commonloader'>
    </div>:null
  }
     <div className="balacepoupformmain general_ledger" >
   <div className="balacepoupformleft">
        <div className="left-blankspace"></div>

        <div className="form-check form-check-inline my-2">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="AToA" defaultChecked={true} onClick={e=>{acTypeClicked(e.target.value)}}/>
  <label className="form-check-label text-dark" for="inlineRadio1">Acc To Acc</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Selected" onClick={e=>{acTypeClicked(e.target.value)}}/>
  <label className="form-check-label text-dark" for="inlineRadio2">Selected Account</label>
</div>
{
  isSelectAc?
  <> <h6 className="form-check-label text-dark" for="inlineRadio2">Selected Account:</h6>
  <div className={classes.AccCheckboxDiv}>
{        ldgAcList.map((data,i)=>
    {
 return( 
  <div className="my-2 px-2">
 <input className="form-check-input mt-0" type="checkbox" name="Mcode" id={data.Mcode} value={data.Mcode} onChange={e=>{handleLedgerChange(e)}}/>
 <label className="form-check-label mx-2" for="inlineRadio2">{data.Acname}</label>
  </div>
)
    })}
      </div>
     </>
  :
  <div>
<h6 className="form-check-label text-dark my-1" for="inlineRadio2">From Account :</h6>
<select className={`form-select ${classes.AccInput}`} name="fromAc"onChange={e=>{onhandleAcChanges(e)}}>
  <option  value="">--Select From Account--</option>
{
   ldgAcList.map((data,i)=>
   {
return(<option key={i} value={data.Mcode}>{data.Acname}</option>)
   })
 }
</select>
<h6 className="form-check-label text-dark my-1" for="inlineRadio2">To Account :</h6>
<select className={`form-select ${classes.AccInput}`} name="toAc" onChange={e=>{onhandleAcChanges(e)}}>
<option value="">--Select To Account--</option>
{
   ldgAcList.map((data,i)=>
   {
return(<option key={i} value={data.Mcode}>{data.Acname}</option>)
   })
 } 
</select>
</div>
}
<div className="row">
<div className="col-sm-6 col-6 col-md-6">
<div className="mb-3">
<h6 className="form-check-label text-dark my-1" for="inlineRadio2">From Date :</h6>
  <input type="date" className="form-control" id="FDate" name="FormDate"onChange={e=>{handleLedgerChange(e)}}/>
</div>
</div>
<div className="col-sm-6 col-6 col-md-6">
      <div className="mb-3">
      <h6 className="form-check-label text-dark my-1" for="inlineRadio2">To Date :</h6>
  <input type="date" className="form-control" id="ToDate"name="ToDate"onChange={e=>{handleLedgerChange(e)}}/>
</div>
</div>
</div>
        <Checkbox label="Rate" size="small" name="chkLocalCurrency"onChange={e=>{handleLedgerChange(e)}}/>
         <span className="text-dark">Report In Local Currency</span>
      <div className="my-1">
      <h6>{nameOfAcc}</h6>
      </div>
         <div className="d-flex mt-2" style={{width:'98%'}}>
         <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} onClick={getLedgerReportInNxtBtn} disabled={ldgAccList?.length<2?true:false}>NEXT</Button>
        <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} disabled={ldgReport?.length==0?true:false}> <CSVLink data={ldgReport} className="text-dark">EXCEL</CSVLink></Button> 
          <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} onClick={getLedgerReport}>VIEW</Button>
          <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} onClick={goBackPage}>EXIT</Button>
         </div>

        </div>
        <div className="balacepoupformright"> 
        <div className='table animatingBottom' style={{height:"97vh",width:"100%"}}>
      <DataGrid
      rows={ldgReport}
      columns={LedgerTblRow}
      // loading={loading}
      className={`${classes[companyColor?.split(',')[0]]}`}
      pageSize={10}
      getRowId={(row) => row.Voucher}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = tabledata.filter((row) =>
            selectedIDs.has(row.Voucher),
         
          );

        }}
        {...ldgReport}
      />
      </div>
      </div>
      </div>
  </>  
  
  );
}

